$(document).ready(function() {

  function markNotificationAsRead(notification_id){
    notification_id = parseInt(notification_id);

    $.ajax({
      type: 'post',
      url: '/notifications/ajax-mark-as-read/' + notification_id,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      },
      success: function (response) {
        //console.log(response.content);
      },
      error: function(e) {
        //console.log(e);
      }
    });
  }


  $('.navbar-notifications-list').on('click', '.navbar-notifications-list-item', function(e){
    //e.preventDefault();
    markNotificationAsRead($(this).attr('data-notification-id'));
  });

  $('.profile-body').on('click', '.navbar-notifications-list-item', function(e){
    //e.preventDefault();
    markNotificationAsRead($(this).attr('data-notification-id'));
  });
});
