$(document).ready(function() {

  function getMorePostComments(post_id, page){
    post_id = parseInt(post_id);
    page = parseInt(page) + 1;

    $.ajax({
      type: 'get',
      url: '/comments/ajax-get-comments-for-post/' + post_id + '/' + page,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      },
      success: function (response) {
        if(response.page){
          $('#more-post-comments-toggle').removeClass('disabled').attr({
            'data-post-id': response.post_id,
            'data-page': response.page
          });
          $('#more-post-comments-toggle').find('i').attr('class', 'far fa-arrow-down');
        } else {
          $('#more-post-comments-toggle').remove();
        }
        if (response.content) {
          $('#comments-wrapper').append(response.content);
        }
      }
     /* error: function(e) {
        console.log(e);
      }*/
    });
  }

  function getMoreCourseComments(course_id, page){
    course_id = parseInt(course_id);
    page = parseInt(page) + 1;

    $.ajax({
      type: 'get',
      url: '/comments/ajax-get-comments-for-course/' + course_id + '/' + page,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      },
      success: function (response) {
        if(response.page){
          $('#more-course-comments-toggle').removeClass('disabled').attr({
            'data-post-id': response.course_id,
            'data-page': response.page
          });
          $('#more-course-comments-toggle').find('i').attr('class', 'far fa-arrow-down');
        } else {
          $('#more-course-comments-toggle').remove();
        }
        if (response.content) {
          $('#comments-wrapper').append(response.content);
        }
      }
     /* error: function(e) {
        console.log(e);
      }*/
    });
  }

  function commentFormForPost(post_id, parent_comment_id){
    post_id = parseInt(post_id);
    parent_comment_id = parseInt(parent_comment_id);

    $.ajax({
      type: 'get',
      url: '/comments/ajax-comment-form-for-post/' + post_id + '/' + parent_comment_id,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      },
      success: function (response) {
        if (response.content) {
          $('#comment-' + parent_comment_id).find('.comment-reply-form').html(response.content);
        }
      }
      /* error: function(e) {
       console.log(e);
       }*/
    });
  }

  function commentFormForCourse(course_id, parent_comment_id){
    course_id = parseInt(course_id);
    parent_comment_id = parseInt(parent_comment_id);

    $.ajax({
      type: 'get',
      url: '/comments/ajax-comment-form-for-course/' + course_id + '/' + parent_comment_id,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      },
      success: function (response) {
        if (response.content) {
          $('#comment-' + parent_comment_id).find('.comment-reply-form').html(response.content);
        }
      }
      /* error: function(e) {
       console.log(e);
       }*/
    });
  }

  function commentEditForm(comment_id){
    comment_id = parseInt(comment_id);

    $.ajax({
      type: 'get',
      url: '/comments/ajax-comment-edit-form/' + comment_id,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      },
      success: function (response) {
        if (response.content) {
          $('#comment-' + comment_id).find('.comment-edit-form').html(response.content);
        }
      }
      /* error: function(e) {
       console.log(e);
       }*/
    });
  }

  function updateCommentRating(comment_id){
    comment_id = parseInt(comment_id);

    $.ajax({
      type: 'post',
      url: '/comments/ajax-update-comment-rating/' + comment_id,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      },
      success: function (response) {
        $('#comment-like-btn-' + comment_id).removeClass('disabled');
        if (response.content) {
          $('#comment-likes-num-' + comment_id).find('span').html(response.content);
          if(parseInt(response.content) <= 0){
            $('#comment-likes-num-' + comment_id).addClass('hidden');
          } else {
            $('#comment-likes-num-' + comment_id).removeClass('hidden');
          }
        }
      },
      error: function(e) {
        //console.log(e);
      }
    });
  }

  $('#more-post-comments-toggle').on('click', function(){
    if($(this).hasClass('disabled')) { return false; }
    $(this).addClass('disabled').blur();
    $(this).find('i').attr('class', 'far fa-sync fa-spin');
    getMorePostComments($(this).attr('data-post-id'), $(this).attr('data-page'));
  });

  $('#more-course-comments-toggle').on('click', function(){
    if($(this).hasClass('disabled')) { return false; }
    $(this).addClass('disabled').blur();
    $(this).find('i').attr('class', 'far fa-sync fa-spin');
    getMoreCourseComments($(this).attr('data-course-id'), $(this).attr('data-page'));
  });

  $('#comments-wrapper').on('click', '.comment-reply-post-btn', function(e){
    e.preventDefault();
    if($(this).hasClass('disabled')) { return false; }
    $(this).addClass('disabled').blur();
    commentFormForPost($(this).attr('data-post-id'), $(this).attr('data-comment-id'));
  });

  $('#comments-wrapper').on('click', '.comment-reply-course-btn', function(e){
    e.preventDefault();
    if($(this).hasClass('disabled')) { return false; }
    $(this).addClass('disabled').blur();
    commentFormForCourse($(this).attr('data-course-id'), $(this).attr('data-comment-id'));
  });

  $('#comments-wrapper').on('click', '.comment-edit-btn', function(e){
    e.preventDefault();
    if($(this).hasClass('disabled')) { return false; }
    $(this).addClass('disabled').blur();
    commentEditForm($(this).attr('data-comment-id'));
  });

  $('#comments-wrapper').on('click', '.comment-cancel-reply-btn', function(e){
    e.preventDefault();
    var parent_comment_id = $(this).attr('data-comment-id');
    $('#comment-reply-form-' + parent_comment_id).remove();
    $('#comment-reply-btn-' + parent_comment_id).removeClass('disabled');
  });

  $('#comments-wrapper').on('click', '.comment-cancel-edit-btn', function(e){
    e.preventDefault();
    var parent_comment_id = $(this).attr('data-comment-id');
    $('#comment-edit-form-' + parent_comment_id).remove();
    $('#comment-edit-btn-' + parent_comment_id).removeClass('disabled');
  });

  $('#comments-wrapper').on('click', '.comment-like-btn', function(e){
    e.preventDefault();
    if($(this).hasClass('disabled')) { return false; }
    $(this).addClass('disabled').blur();
    updateCommentRating($(this).attr('data-comment-id'));
  });

});
