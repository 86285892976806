$(document).ready(function() {
  $('a[href=\\#]').bind("click", function (e) {
    e.preventDefault();
  });
  $('a[href*=\\#].scroll').bind("click", function (e) {
    var top = $($(this).attr('href')).offset().top - 100;
    e.preventDefault();
    $('html, body').stop().animate({scrollTop: top}, 1000);
  });
  $('a.scroll').bind("click", function (e) {
    var top = $($(this).attr('href')).offset().top - 100;
    e.preventDefault();
    $('html, body').stop().animate({scrollTop: top}, 1000);
  });

  // *only* if we have anchor on the url
  if(window.location.hash) {

    // smooth scroll to the anchor id
    $('html, body').animate({
      scrollTop: ($(window.location.hash).offset().top - 100)
    }, 1000);
  }

  $('[data-toggle="tooltip"]').tooltip();

  var header_nav = $('#header-nav');
  header_nav.on('click', 'a:not([data-toggle="dropdown"])', function () {
    header_nav.collapse('hide');
  }).on('show.bs.collapse', function () {
    $('.header').addClass('open');
  }).on('hide.bs.collapse', function () {
    $('.header').removeClass('open');
  });

  $('.header').on('click', '.navbar-notifications-toggle', function () {
    header_nav.collapse('hide');
  });

  if(!['/', '/uk/', '/ru/', '/en/', '/uk', '/ru', '/en'].includes(window.location.pathname)) {
    $('.header').addClass('header-filled');
    $('.header').addClass('header-shadow');
    $('.icon-brand').addClass('icon-brand-filled');
  } else {
    if($(window).scrollTop()) { //abuse 0 == false :)
      $('.header').addClass('header-filled');
      $('.header').addClass('header-shadow');
      $('.icon-brand').addClass('icon-brand-filled');
    }

    $(window).scroll(function () {
      if ($(this).scrollTop() > 2) {
        $('.header').addClass('header-filled');
        $('.header').addClass('header-shadow');
        $('.icon-brand').addClass('icon-brand-filled');
      }
      else{
        $('.header').removeClass('header-filled');
        $('.header').removeClass('header-shadow');
        $('.icon-brand').removeClass('icon-brand-filled');
      }
    });
  }

  if($(window).scrollTop()) { //abuse 0 == false :)
    $('.header').addClass('header-fixed');
    $('.header').addClass('header-shadow');
  }

  $(window).scroll(function () {
    if ($(this).scrollTop() > 2) {
      $('.header').addClass('header-fixed');
      $('.header').addClass('header-shadow');
    }
    else{
      $('.header').removeClass('header-fixed');
      $('.header').removeClass('header-shadow');
    }
  });

  $('.panel-collapse').on('show.bs.collapse', function () {
    $(this).parents('.panel').addClass('active');
  }).on('hide.bs.collapse', function () {
    $(this).parents('.panel').removeClass('active');
  });

  function getIpinfo(){
    return $.get("https://ipinfo.io?token=2f6c0e302d4989", function () {}, "jsonp");
  }

  function setCountry(country) {
    if (country != ''){
      Cookies.set('country', country, {expires: 1});
    }
  }

  var country = Cookies.get('country');
  if(!country) {
    getIpinfo().always(function (resp) {
      country = (resp && resp.country) ? resp.country : "";
      setCountry(country);
    });
  }

  $('.set-language').on('click', function (e){
    e.preventDefault();
    var language = $(this).attr('data-language-code');
    if(language.length > 0){
      Cookies.set('language', language, {expires: 30});
    }
    window.location.href = this.href;
  });

  var isDNT = window.doNotTrack == "1" || navigator.doNotTrack == "yes" ||
    navigator.doNotTrack == "1" || navigator.msDoNotTrack == "1";

  if(!Cookies.get('cookie_policy') && !isDNT){
    $('#cookies-alert').css('display', 'block');
  }

  $('#cookie-policy-accept').click(function(){
    Cookies.set('cookie_policy', 'true', {expires: 365});
    $('#cookies-alert').css('display', 'none');
  });

  if ($(location.hash).length && $(location.hash).hasClass('modal')) {
    $(location.hash).modal('show');
  }

  function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  var referrer = getParameterByName('ref');
  var utm_source = getParameterByName('utm_source');
  var utm_medium = getParameterByName('utm_medium');
  var utm_campaign = getParameterByName('utm_campaign');
  var utm_term = getParameterByName('utm_term');
  var utm_content = getParameterByName('utm_content');

  if(referrer){
    Cookies.set('ref_hash', referrer, { expires: 7 });
  }

  if(utm_source){
    Cookies.set('utm_source', utm_source, { expires: 1 });
    if(utm_medium){
      Cookies.set('utm_medium', utm_medium, { expires: 1 });
    } else {
      Cookies.remove('utm_medium');
    }
    if(utm_campaign){
      Cookies.set('utm_campaign', utm_campaign, { expires: 1 });
    } else {
      Cookies.remove('utm_campaign');
    }
    if(utm_term){
      Cookies.set('utm_term', utm_term, { expires: 1 });
    } else {
      Cookies.remove('utm_term');
    }
    if(utm_content){
      Cookies.set('utm_content', utm_content, { expires: 1 });
    } else {
      Cookies.remove('utm_content');
    }
  }

  $('input[type="tel"]').intlTelInput({
    // allowDropdown: false,
    // autoHideDialCode: false,
    // autoPlaceholder: "off",
    // dropdownContainer: "body",
    // excludeCountries: ["us"],
    // formatOnDisplay: false,
    geoIpLookup: function(callback) {
      if(!country) {
        getIpinfo().always(function (resp) {
          country = (resp && resp.country) ? resp.country : "";
          setCountry(country);
          callback(country);
        });
      }
      else{
        callback(country);
      }
    },
    //hiddenInput: "phone",
    initialCountry: "ua",
    nationalMode: false,
    // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
    // placeholderNumberType: "MOBILE",
    preferredCountries: ['ua'],
    // separateDialCode: true,
    utilsScript: "/js/intlTelInput/utils.js"
  });

  $.validator.methods.phone = function( value, element ) {
    return this.optional( element ) || $(element).intlTelInput("isValidNumber");
  };

  $('.order-form').each(function(){
    $(this).validate({
      rules: {
        name: {
          required: true,
          minlength: 2,
          maxlength: 255
        },
        email: {
          required: true,
          email: true
        },
        phone: {
          required: true,
          phone: true
        },
        promocode: {
          minlength: 2,
          maxlength: 50
        }
      },
      messages: {
        name: {
          required: i18next.t("Обязательное поле"),
          minlength: i18next.t("Минимальная длина строки 2 символа"),
          maxlength: i18next.t("Максимальная длина строки 255 символов")
        },
        email: {
          required: i18next.t("Обязательное поле"),
          email: i18next.t("Введите корректный email")
        },
        phone: {
          required: i18next.t("Обязательное поле"),
          phone: i18next.t("Введите корректный номер телефона")
        },
        promocode: {
          minlength: i18next.t("Минимальная длина строки 2 символа"),
          maxlength: i18next.t("Максимальная длина строки 50 символов")
        }
      },
      submitHandler: function(form) {
        $(form).find('.btn-submit').addClass('btn-icon disabled').attr('disabled', true).blur();
        $(form).find('.btn-submit').html('<span>' + i18next.t("Переходим к оплате") + '</span><i class="far fa-sync fa-spin"></i>');
        let path_items = window.location.pathname.split('/');
        if (path_items[path_items.length - 2] !== 'registration-for-course') {
          fbq('track', 'Lead');
        }
        gtag('event', 'conversion_event_submit_lead_form_2');
        form.submit();
      }
    });
  });

  $('.early-bird-course-form').each(function(){
    $(this).validate({
      rules: {
        name: {
          required: true,
          minlength: 2,
          maxlength: 255
        },
        email: {
          required: true,
          email: true
        },
        phone: {
          required: true,
          phone: true
        },
        promocode: {
          minlength: 2,
          maxlength: 50
        }
      },
      messages: {
        name: {
          required: i18next.t("Обязательное поле"),
          minlength: i18next.t("Минимальная длина строки 2 символа"),
          maxlength: i18next.t("Максимальная длина строки 255 символов")
        },
        email: {
          required: i18next.t("Обязательное поле"),
          email: i18next.t("Введите корректный email")
        },
        phone: {
          required: i18next.t("Обязательное поле"),
          phone: i18next.t("Введите корректный номер телефона")
        },
        promocode: {
          minlength: i18next.t("Минимальная длина строки 2 символа"),
          maxlength: i18next.t("Максимальная длина строки 50 символов")
        }
      },
      submitHandler: function(form) {
        $(form).find('.btn-submit').addClass('btn-icon disabled').attr('disabled', true).blur();
        $(form).find('.btn-submit').html('<span>' + i18next.t("Подождите") + '</span><i class="far fa-sync fa-spin"></i>');

        // Track Leads via js only for courses with id not from [9,30,31,32]
        let path_items = window.location.pathname.split('/');
        if (
          path_items[path_items.length - 2] === 'registration-for-course'
          && !['9','30','31','32'].includes(path_items[path_items.length - 1])
        ) {
          fbq('track', 'Lead');
          gtag('event', 'conversion_event_submit_lead_form_2');
        }

        form.submit();
      }
    });
  });

  $('.order-donate-form').each(function(){
    $(this).validate({
      rules: {
        name: {
          required: true,
          minlength: 2,
          maxlength: 255
        },
        email: {
          required: true,
          email: true
        },
        phone: {
          required: true,
          phone: true
        },
        promocode: {
          minlength: 2,
          maxlength: 50
        },
        comment: {
          minlength: 2,
          maxlength: 500
        },
        order_sum_input: {
          number: true,
          min: 1,
          max: 10000
        }
      },
      messages: {
        name: {
          required: i18next.t("Обязательное поле"),
          minlength: i18next.t("Минимальная длина строки 2 символа"),
          maxlength: i18next.t("Максимальная длина строки 255 символов")
        },
        email: {
          required: i18next.t("Обязательное поле"),
          email: i18next.t("Введите корректный email")
        },
        phone: {
          required: i18next.t("Обязательное поле"),
          phone: i18next.t("Введите корректный номер телефона")
        },
        promocode: {
          minlength: i18next.t("Минимальная длина строки 2 символа"),
          maxlength: i18next.t("Максимальная длина строки 50 символов")
        },
        comment: {
          minlength: i18next.t("Минимальная длина строки 2 символа"),
          maxlength: i18next.t("Максимальная длина строки 500 символов")
        },
        order_sum_input: {
          number: i18next.t("Введите корректное значение"),
          min: i18next.t("Введите значение большее или равное 1"),
          max: i18next.t("Введите значение меньшее или равное 10000")
        }
      },
      submitHandler: function(form) {
        $(form).find('.btn-submit').addClass('btn-icon disabled').attr('disabled', true).blur();
        $(form).find('.btn-submit').html('<span>' + i18next.t("Переходим к оплате") + '</span><i class="far fa-sync fa-spin"></i>');
        form.submit();
      }
    });
  });

  $('.subscribe-form').each(function(){
    $(this).validate({
      rules: {
        email: {
          required: true,
          email: true
        }
      },
      messages: {
        email: {
          required: i18next.t("Обязательное поле"),
          email: i18next.t("Введите корректный email")
        }
      }
    });
  });

  $('.comment-form').each(function(){
    $(this).validate({
      rules: {
        content: {
          required: true,
          minlength: 2,
          maxlength: 2000
        }
      },
      messages: {
        content: {
          required: i18next.t("Обязательное поле"),
          minlength: i18next.t("Минимальная длина строки 2 символа"),
          maxlength: i18next.t("Максимальная длина строки 2000 символов")
        }
      }
    });
  });

  var more_partners_text = '';
  $('.more-partners-toggle').on('click', function () {
    var hide_text = $(this).data('hide');
    if (!$(this).hasClass('active')) {
      more_partners_text = $(this).text();
      $(this).addClass('active');
      $(this).text(hide_text);
    } else {
      $(this).removeClass('active');
      $(this).text(more_partners_text);
    }
  });

  $(document).on('click', '.message-close', function(){
    var parent = $(this).parent('.message');
    parent.removeClass('slideInUp').addClass('slideOutDown');
    setTimeout(function(){
      parent.css('display', 'none');
    }, 300);
  });

  $('.more-programs-toggle').on('click', function () {
    $(this).hide();
  });

  $('.modal').on('show.bs.modal',function(){
    setTimeout(function(){
      $('body').addClass('modal-open');
    },800);
  }).on('hide.bs.modal', function(){
    $('body').css('padding-right', '0');
  });

  function youtube_parser(url){
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match && match[2].length == 11) ? match[2] : false;
  }

  $('.post-about iframe').each(function(){
    var iframe = $(this);
    var url = $(iframe).attr('src');
    if(url){
      $(iframe).wrap('<div class="post-about-video video-iframe"></div>')
    }
  });

  $('.page-title').each(function(){
    var title_length = $(this).text().length;
    if(title_length < 25){
      $(this).addClass('page-title-lg');
    }
  });

  var btn_clipboard = new ClipboardJS('.btn-clipboard');
  btn_clipboard.on('success', function(e) {
    var message = '<div class="message animated slideInUp">' +
      '<span class="message-close">×</span>' +
      '<p>' +
      $(e.trigger).data('clipboardMessage') +
      '</p>' +
      '</div>';
    $('.messages-wrapper').append(message);
  }).on('error', function() {
    var message = '<div class="message animated slideInUp">' +
      '<span class="message-close">×</span>' +
      '<p>' +
      i18next.t("Нажмите Ctrl+C")  +
      '</p>' +
      '</div>';
    $('.messages-wrapper').append(message);
  });

  $('.course-benefits-slider').slick({
    dots: false,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    infinite: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.course-reviews-slider').slick({
    dots: false,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    infinite: true,
    adaptiveHeight: true,
    prevArrow: $('#course-reviews-slider-nav-prev'),
    nextArrow: $('#course-reviews-slider-nav-next'),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false
        }
      }
    ]
  });

  $('.home-blog-posts-slider').slick({
    dots: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  });

  $('.home-courses-slider').slick({
    dots: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  });

  $('.home-articles-slider').slick({
    dots: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  });

  $('.home-podcasts-slider').slick({
    dots: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  });

  $('.home-videos-slider').slick({
    dots: false,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  });

  $('.other-posts-slider').slick({
    dots: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  });

  $('.special-page-courses-slider').slick({
    rows: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });

  if($('.post-video-player-wrapper').find('.post-list-thumbnails').length > 0){
    setTimeout(function(){
      var height = $('.post-video-player-wrapper .post-cover').outerHeight();
      console.log(height);
      $('.post-video-player-wrapper').find('.post-list-thumbnails').css('height', height + 'px');
    }, 1000)
  }

  $('.home-packages-description').mCustomScrollbar({
    theme: "orange-theme"
  });

  function updatePackagePrice(promocode, package_id){

    $.ajax({
      type: 'post',
      url: '/orders/ajax-get-discount-package-price',
      data: {
        "package_id": package_id,
        "promocode": promocode
      },
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      },
      success: function (response) {
        $('.order-form .btn-submit span').text(response.price);
      }
      /*error: function(e) {
        console.log('error');
      }*/
    });
  }

  function updateCoursePrice(promocode, course_id){

    $.ajax({
      type: 'post',
      url: '/orders/ajax-get-discount-course-price',
      data: {
        "course_id": course_id,
        "promocode": promocode
      },
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      },
      success: function (response) {
        $('.order-form .btn-submit span').text(response.price);
      },
      error: function(e) {
        console.log(e.responseText);
      }
    });
  }

  function updateCombinedPackagePrice(promocode, combined_package_id){

    $.ajax({
      type: 'post',
      url: '/orders/ajax-get-discount-combined-package-price',
      data: {
        "combined_package_id": combined_package_id,
        "promocode": promocode
      },
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      },
      success: function (response) {
        $('.order-form .btn-submit span').text(response.price);
      },
      error: function(e) {
        console.log(e.responseText);
      }
    });
  }

  $('#order-form-promocode-btn').on('click', function(){
    var package_id = $('.order-form').find('input[name=package_id]').val();
    var course_id = $('.order-form').find('input[name=course_id]').val();
    var combined_package_id = $('.order-form').find('input[name=combined_package_id]').val();
    var promocode = $('.order-form').find('input[name=promocode]').val().trim();

    if(typeof(promocode) != "undefined" && promocode !== null && promocode.length) {

      if(typeof(package_id) != "undefined" && package_id !== null && package_id.length){
        updatePackagePrice(promocode, package_id);
      }

      if(typeof(course_id) != "undefined" && course_id !== null && course_id.length){
        updateCoursePrice(promocode, course_id);
      }

      if(typeof(combined_package_id) != "undefined" && combined_package_id !== null && combined_package_id.length){
        updateCombinedPackagePrice(promocode, combined_package_id);
      }
    }
  });

  $('#copy_to_clipboard').on('click', function (){
    navigator.clipboard.writeText(window.location.href);
    window.alert('Посилання на сторінку скопійовано');
  });

  // ANCHORS
  var $anchors = $('.js-anchor');

  $anchors.on('click', function(e) {
    e.preventDefault();

    var id = $(this).attr('href');

    $('html, body').animate({scrollTop:$(id).offset().top - 110 }, 800);
    navHide();
  });

  // STICKY FOR NEW COURSE
  let leftCourseBlock = $('#left-course-block');
  $(window).scroll(function () {
    /* 105 - height of header and margin */
    /* 36 - padding down */
    let leftCourseBlockWidth = document.getElementById('left-course-block').getBoundingClientRect().width;
    let leftCourseBlockSizes = document.getElementById('left-course-block').getBoundingClientRect();
    let rightCoursesColumnSizes = document.getElementById('right-courses-column').getBoundingClientRect();

    if (rightCoursesColumnSizes.y >= 105) {
      leftCourseBlock.removeClass('left-course-fixed');
      leftCourseBlock.removeClass('left-course-absolute');
      document.getElementById('left-course-block').style.width = '';
    } else if (
      rightCoursesColumnSizes.y < 105
      && (rightCoursesColumnSizes.y > leftCourseBlockSizes.height - rightCoursesColumnSizes.height + 72 + 36)
    ) {
      leftCourseBlock.removeClass('left-course-absolute');
      leftCourseBlock.addClass('left-course-fixed');
      document.getElementById('left-course-block').style.width = leftCourseBlockWidth + 'px';
    } else {
      leftCourseBlock.removeClass('left-course-fixed');
      leftCourseBlock.addClass('left-course-absolute');
      document.getElementById('left-course-block').style.width = leftCourseBlockWidth + 'px';
    }
  });

});

$(function (){
  AOS.init({
    duration: 700,
    once: true,
    disable: 'mobile'
  });
})
