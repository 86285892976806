$(document).ready(function() {
  var pathArray = window.location.pathname.split('/');
  var cookie_lang = Cookies.get('language');
  var lang = cookie_lang ? cookie_lang : (pathArray[1] ? pathArray[1] : 'ru');
  i18next.init({
    //"debug": true,
    "lng": lang,
    "fallbackLng": false,
    "keySeparator": false,
    "nsSeparator": false,
    "resources": {
      "uk": {
        "translation": {
          "января": "січня",
          "февраля": "лютого",
          "марта": "березня",
          "апреля": "квітня",
          "мая": "травня",
          "июня": "червня",
          "июля": "липня",
          "августа": "серпня",
          "сентября": "вересня",
          "октября": "жовтня",
          "ноября": "листопада",
          "декабря": "грудня",
          "с": "з",
          "Обязательное поле": "Обов'язкове поле",
          "Минимальная длина имени 2 символа": "Мінімальна довжина рядка 2 символи",
          "Максимальная длина имени 100 символов": "Максимальна довжина рядка 100 символів",
          "Минимальная длина строки 2 символа": "Мінімальна довжина рядка 2 символи",
          "Максимальная длина строки 100 символов": "Максимальна довжина рядка 100 символів",
          "Максимальная длина строки 255 символов": "Максимальна довжина рядка 255 символів",
          "Максимальная длина строки 1000 символов": "Максимальна довжина рядка 1000 символів",
          "Максимальная длина строки 5 символов": "Максимальна довжина рядка 5 символів",
          "Введите корректный email": "Введіть коректний email",
          "Введите корректный номер телефона": "Введіть коректний номер телефону",
          "Максимальная длина промокода 50 символов": "Максимальна довжина рядка 50 символів",
          "Введите корректное число": "Введіть коректне число",
          "Подробнее": "Детальніше",
          "Скрыть": "Приховати",
          "Переходим к оплате": "Переходимо до оплати",
          "Подождите": "Зачекайте"
        }
      },
      "en": {
        "translation": {
          "января": "January",
          "февраля": "February",
          "марта": "March",
          "апреля": "April",
          "мая": "May",
          "июня": "June",
          "июля": "July",
          "августа": "August",
          "сентября": "September",
          "октября": "October",
          "ноября": "November",
          "декабря": "December",
          "с": "from",
          "Обязательное поле": "Required field",
          "Минимальная длина имени 2 символа": "Minimum length of name is 2 symbols",
          "Максимальная длина имени 100 символов": "Maximum length of name is 100 symbols",
          "Минимальная длина строки 2 символа": "Minimum length of string is 2 symbols",
          "Максимальная длина строки 100 символов": "Maximum length of string is 100 symbols",
          "Максимальная длина строки 255 символов": "Maximum length of string is 255 symbols",
          "Максимальная длина строки 1000 символов": "Maximum length of string is 1000 symbols",
          "Максимальная длина строки 5 символов": "Maximum length of string is 5 symbols",
          "Введите корректный email": "Please use a correct e-mail",
          "Введите корректный номер телефона": "Please use a correct phone number",
          "Максимальная длина промокода 50 символов": "Maximum length of promocode is 40 symbols",
          "Введите корректное число": "Please enter a valid number",
          "Подробнее": "Read more",
          "Скрыть": "Hide",
          "Переходим к оплате": "Proceed to payment",
          "Подождите": "Please wait"
        }
      }
    }
  });
});
