$(document).ready(function() {

  function getMoreCourseReviews(course_id, page){
    course_id = parseInt(course_id);
    page = parseInt(page) + 1;

    $.ajax({
      type: 'get',
      url: '/reviews/ajax-get-reviews-for-course/' + course_id + '/' + page,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      },
      success: function (response) {
        if(response.page){
          $('#more-course-reviews-toggle').removeClass('disabled').attr({
            'data-post-id': response.course_id,
            'data-page': response.page
          });
          $('#more-course-reviews-toggle').find('i').attr('class', 'far fa-arrow-down');
        } else {
          $('#more-course-reviews-toggle').remove();
        }
        if (response.content) {
          $('#course-reviews-wrapper').append(response.content);
        }
      }
      /*error: function(e) {
        console.log(e.responseText);
      }*/
    });
  }

  $('#more-course-reviews-toggle').on('click', function(){
    if($(this).hasClass('disabled')) { return false; }
    $(this).addClass('disabled').blur();
    $(this).find('i').attr('class', 'far fa-sync fa-spin');
    getMoreCourseReviews($(this).attr('data-course-id'), $(this).attr('data-page'));
  });

});