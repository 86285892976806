$(document).ready(function() {

  var quiz_form = $("#form-quiz");


  function generateValidationRules(){
    var validation_rules = {};

    $("#form-quiz").find("input:checkbox").each(function () {
      var input_name = $(this).attr('name');
      var input_group_id = $(this).attr('data-group-id');
      validation_rules[input_name] = {
        require_from_group: [1, ".option-group-" + input_group_id]
      };
    });

    return validation_rules;
  }

  quiz_form.validate({
    rules: generateValidationRules(),
    errorPlacement: function(error, element) {
      // Don't show error
    }
  });

  quiz_form.steps({
    headerTag: "h4",
    bodyTag: "fieldset",
    stepsOrientation: "horizontal",
    titleTemplate: '<span class="step">#index#</span>',
    cssClass: 'wizard wizard-simple',
    labels: {
      cancel: "Отмена",
      current: "Шаг:",
      pagination: "Pagination",
      finish: "Завершить",
      next: "<span>Далее</span><i class='far fa-arrow-right'></i>",
      previous: "<i class='far fa-arrow-left'></i>",
      loading: "Подождите..."
    },
    onStepChanging: function (event, currentIndex, newIndex) {
      // Always allow previous action even if the current form is not valid!
      if (currentIndex > newIndex)
      {
        return true;
      }
      // Needed in some cases if the user went back (clean up)
      if (currentIndex < newIndex)
      {
        // To remove error styles
        quiz_form.find(".body:eq(" + newIndex + ") label.error").remove();
        quiz_form.find(".body:eq(" + newIndex + ") .error").removeClass("error");
      }

      return quiz_form.valid();
    },
    onStepChanged: function (event, currentIndex, priorIndex) {
      if (priorIndex > currentIndex)
      {
        quiz_form.find(".steps>ul>li:gt(" + currentIndex + ").done").removeClass("done").addClass("disabled");
        quiz_form.find(".steps>ul>li:eq(" + currentIndex + ").done").removeClass("done");
      }
    },
    onFinishing: function (event, currentIndex) {
      return quiz_form.valid();
    },
    onFinished: function (event, currentIndex) {
      quiz_form.submit();
    }
  });


  var i = 1;
  $(quiz_form).find(".post-quiz-item-type-radio").each(function () {
    var group = $(this);
    $(group).addClass("group-" + i);

    $(group).find("input:checkbox").each(function () {
      $(this).removeClass("checkbox-outline").addClass("radio-outline");
      $(this).change(function () {
        $(group).find("input:checkbox").not($(this)).prop("checked", false);
        $(this).prop("checked", true);
      });
    });
    i++;
  });
});
