$(document).ready(function(){

  $('.login-form').each(function(){
    $(this).validate({
      rules: {
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 6,
          maxlength: 255
        }
      },
      messages: {
        email: {
          required: i18next.t("Поле обязательно для заполнения"),
          email: i18next.t("Введите корректный email")
        },
        password: {
          required: i18next.t("Поле обязательно для заполнения"),
          minlength: i18next.t("Пароль должен содержать хотя бы 6 символов"),
          maxlength: i18next.t("Максимальная длина пароля 255 символов")
        }
      }
    });
  });

  $('.guest-login-form').each(function(){
    $(this).validate({
      rules: {
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 3,
          maxlength: 255
        }
      },
      messages: {
        email: {
          required: i18next.t("Поле обязательно для заполнения"),
          email: i18next.t("Введите корректный email")
        },
        password: {
          required: i18next.t("Поле обязательно для заполнения"),
          minlength: i18next.t("Пароль должен содержать хотя бы 3 символа"),
          maxlength: i18next.t("Максимальная длина пароля 255 символов")
        }
      }
    });
  });

  $('.reset-password-form').each(function(){
    $(this).validate({
      rules: {
        email: {
          required: true,
          email: true
        }
      },
      messages: {
        email: {
          required: i18next.t("Поле обязательно для заполнения"),
          email: i18next.t("Введите корректный email")
        }
      }
    });
  });

  $('.profile-main-form').each(function(){
    $(this).validate({
      rules: {
        name: {
          required: true,
          minlength: 2,
          maxlength: 255
        },
        email: {
          required: true,
          email: true
        },
        phone: {
          required: true,
          phone: true
        }
      },
      messages: {
        name: {
          required: i18next.t("Поле обязательно для заполнения"),
          minlength: i18next.t("Минимальная длина строки 2 символа"),
          maxlength: i18next.t("Максимальная длина строки 255 символов")
        },
        email: {
          required: i18next.t("Поле обязательно для заполнения"),
          email: i18next.t("Введите корректный email")
        },
        phone: {
          required: i18next.t("Поле обязательно для заполнения"),
          phone: i18next.t("Введите корректный номер телефона")
        },
        country_id: {
          required: i18next.t("Поле обязательно для заполнения")
        },
        company: {
          minlength: i18next.t("Минимальная длина строки 2 символа"),
          maxlength: i18next.t("Максимальная длина строки 255 символов")
        }
      }
    });
  });

  $('.profile-password-form').each(function(){
    $(this).validate({
      rules: {
        current_password: {
          required: true,
          minlength: 6,
          maxlength: 255
        },
        new_password: {
          required: true,
          minlength: 6,
          maxlength: 255
        },
        new_password_repeat: {
          required: true,
          minlength: 6,
          maxlength: 255
        }
      },
      messages: {
        current_password: {
          required: i18next.t("Поле обязательно для заполнения"),
          minlength: i18next.t("Минимальная длина строки 6 символов"),
          maxlength: i18next.t("Максимальная длина строки 255 символов")
        },
        new_password: {
          required: i18next.t("Поле обязательно для заполнения"),
          minlength: i18next.t("Минимальная длина строки 6 символов"),
          maxlength: i18next.t("Максимальная длина пароля 255 символов")
        },
        new_password_repeat: {
          required: i18next.t("Поле обязательно для заполнения"),
          minlength: i18next.t("Минимальная длина строки 6 символов"),
          maxlength: i18next.t("Максимальная длина пароля 255 символов")
        }
      }
    });
  });

});