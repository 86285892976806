$(document).ready(function() {

  function onKeyUp(){
    var value = $(this).val().trim();
    value = value.replace("+", "");

    if(value.length === 0) {
      $('#search_popup').html('').hide(100);
    }
    else{
      var targeturl = $(this).attr('rel') + '?q=' + value;
      var referrer = $('.form-search-js').find('input[name=referrer]').val();
      if(referrer && referrer.length > 0){
        targeturl = targeturl + '&referrer=' + referrer;
      }

      $.ajax({
        type: 'get',
        url: targeturl,
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        },
        success: function (response) {
          if (response.content) {
            $('#search_popup').html(response.content).show(100);
          }
          else {
            $('#search_popup').html('').hide(100);
          }
        },
        error: function(e) {
         //alert("An error occurred: " + e.responseText.message);
         console.log(e);
        }
      });
    }
  }
  $('.form-search-input').keyup($.debounce(onKeyUp, 300)).focus(function(){
    var popup = $('#search_popup');
    if(popup.html().length !== 0){
      popup.show(100);
    }
  });

  $(document).mouseup(function(event) {
    if ($(event.target).closest("#search_popup").length) return;
    if ($(event.target).closest(".form-search-input").length) return;
    $('#search_popup').hide(100);
    event.stopPropagation();
  });

  $('#form-search').submit(function(e){
    e.preventDefault();
  })

  $('#start_date').on('change', function(event) {
    let search_link = '';
    let attr_pathname = $(this).attr('rel').split('?')[0];

    if (attr_pathname === window.location.pathname) {
      search_link = window.location.pathname
        + replaceQueryParam('start_date', event.target.value, window.location.search);
    } else {
      let delimiter = $(this).attr('rel').includes('?') ? '&' : '?';
      search_link = $(this).attr('rel') + delimiter + 'start_date=' + event.target.value;
      let referrer = $('.form-search-js').find('input[name=referrer]').val();
      if(referrer && referrer.length > 0){
        search_link = search_link + '&referrer=' + referrer;
      }
    }

    window.location.href = search_link;
  });

  $('#end_date').on('change', function(event) {
    let search_link = '';
    let attr_pathname = $(this).attr('rel').split('?')[0];

    if (attr_pathname === window.location.pathname) {
      search_link = window.location.pathname
        + replaceQueryParam('end_date', event.target.value, window.location.search);
    } else {
      let delimiter = $(this).attr('rel').includes('?') ? '&' : '?';
      search_link = $(this).attr('rel') + delimiter + 'end_date=' + event.target.value;
      let referrer = $('.form-search-js').find('input[name=referrer]').val();
      if(referrer && referrer.length > 0){
        search_link = search_link + '&referrer=' + referrer;
      }
    }

    window.location.href = search_link;
  });

  function replaceQueryParam(param, new_param, search) {
    let regex = new RegExp('([?;&])' + param + '[^&;]*[;&]?');
    let query = search.replace(regex, '$1').replace(/&$/, '');

    return (query.length > 2 ? query + '&' : '?') + (new_param ? param + '=' + new_param : '');
  }
});
